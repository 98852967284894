$enbridgeYellow: #FFB81C;
$bodyCopyBlack: #000000;
$charcoalGrey: #353535;
$enbridgeGrey: #555555;
$lightblack: #cccccc;
$steel: #D0D0CE;
$lightGrey: #d0d0ce;
$softYellow: #FFF6E3;
$cherryAlertRed: #C8102E;
$curiousBlue: #4296BD;
$lightLineColor: #B4B4B4;
$darkLineColor: #585858;
$yellowLineColor: #FCB61C;
$white: #ffffff;
$whitegrey: #f2f3f3;
$lightRed: #FAE7E9;
$palegrey:#eaeaed;
$black:#000000;
$yellowTabColor:#FFB81B;
$Aquamarine:#04A773;
$tableBackground:#d8d8d8;
$greentick:#4a773c;
$errortick: #f7dbe0;
$errorRed: #cd1231;
$red: #ff0000;
$black-text: #333333;
$stepsInfoColor: #9B9B9B;
$whitebg: #f0f0f0;
$blueselection: #0488E4;
$greybg: #E5F3FC;
$highlightsbg: #f7f7f7;
$midgrey: #787878;
$yellowBtnHover: #FFC649;
$alertBg: #F9E7EA;
$greyBtnHover: #666666;
$greyBorderTextarea: #979797;
$link-blue: #4A90E2;
$green-border: #6CC24A;
$lightgrey-bg: #F0F8EC;
$dark-gray: #4a4a4a;
$light-gray1: #dcdcdc;
$lgreymobile: #f0f0f0;
$lightGreyBackground: #FFF7E8;
$enbridge-grey2: #BDBDBD;
$enbridgeBlue: #007DBA;
// /////////////////  FOR STYLE GUIDE \\\\\\\\\\\\\\\\\\\\\
.rect {
    float: left;
    height: 100px;
    width: 125px;
}

.enbridgeYellow {
    background-color: $enbridgeYellow;
    color: lighten($enbridgeYellow,50%);
}

.bodyCopyBlack {
    background-color: $bodyCopyBlack;
    color: lighten($bodyCopyBlack,50%);
}

.charcoalGrey {
    background-color: $charcoalGrey;
    color: lighten($charcoalGrey,50%);
}

.enbridgeGrey {
    background-color: $enbridgeGrey;
    color: lighten($enbridgeGrey,50%);
}

.steel {
    background-color: $steel;
    color: black;
}

.lightGrey {
    background-color: $lightGrey;
    color: black;
}

.softYellow {
    background-color: $softYellow;
    color: black;
}

.cherryAlertRed {
    background-color: $cherryAlertRed;
    color: lighten($cherryAlertRed,50%);
}

.enbridgeWhite {
    background-color: $white;
}

//end: FOR STYLE GUIDE ~~~~~~~~~~~~~~~~~~~~~~
