﻿.nav-list {
    display: flex;
    list-style: none;
    justify-content: space-between;
    padding: 0px;
    margin: 0px;
}

div.p-0,p.p-0, fieldset.p-0{
    padding: 0px;
}
p.m-0{
    margin: 0px;
}
div.pl-0,fieldset.pl-0{
    padding-left: 0px;
}

div.pr-0,fieldset.pr-0{
    padding-right: 0px;
}
#antyforgery-Msg,#apiRateLimit-Msg {
    display: none;
}
.welcome-message-container{
    display: none;
    padding-bottom: 5rem;
}
.welcome-message-box {
    padding: 2rem;
    display: flex;
    align-items: center;
}

.welcome-message-box h3 {
    padding: 0px;
}
/*.validate-content > p {
    margin: 0px;
    padding: 0px;
}*/
.hwp-checkbox-control[type='checkbox']{
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
    float: left;
    margin-right: 15px;
    margin-top: 0px;
}

.question-tooltip
{
    display: inline-block;
    position: relative;
}
.question-tooltip .question-tooltiptext {
    visibility: hidden;
    min-width: 320px;
    background-color: #555;
    color:$white;
    text-align: center;
    padding: 0.5rem;
    border-radius: 6px;
    position: absolute;
    z-index: 10;
    bottom: 112%;
    left:0px;
    margin-left: -14px;
    opacity: 0;
    transition: opacity 0.3s;
}
.question-tooltip .question-tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 20px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.question-tooltip:hover .question-tooltiptext {
    visibility: visible;
    opacity: 1;
}

.nav-list li {
    width: 175px;
    flex-shrink: 1;
    font-size: 1.1rem;
    border-bottom: 4px solid #eaeaea;
    padding-bottom: 2rem;
    display: flex;
    align-items: flex-end;
}

.leads-form-container, .element-error-msg, .cookies-label {
    display: none;
}
.cookies-label.active {
    display:  block;
}



.nav-list li span.progress-number {
    font-size: 1.8rem;
}

.nav-list li.active {
    border-bottom: 4px solid $yellowTabColor;
}

.bg-gray {
    background-color: $enbridgeGrey;
}

.bg-white {
    background-color: $white;
}


.HWP-main-container legend
{
    border:none;
    margin: 0px;
    font-size: 1rem;
    display: none;
}
.hwp-button-container .prev {
    margin-right: 1rem;
}

.email-not-matched, legend, .confirmemail-not-matched {
    display: none;
}

.list-container{
    padding: 3rem 2.5rem 3rem 2.5rem;
}

.content-container {
	 padding:0px 2.5rem 2.5rem 2.5rem;


}

.content-container {
    display: none;
}

.content-container.active {
    display: block;
}
.HWP-main-container
{
    padding-top:4rem;
	padding-bottom: 4rem;
}

[for='LeadFormData_UserAcceptance'] {
    margin-top: 1rem;
}

.hwp-button-container {
    padding: 0px;
    margin-top: 2rem;
}


.form-control.hwp-input-control {

    position: relative;

}

.form-control.hwp-input-control[readonly], .form-control.hwp-input-control[disabled] {
    background: $whitegrey !important;
    background-color: $whitegrey !important;
}

.sub-question-container,
.hwp-error {
    display: none;
}

.sub-question-container.active,
.hwp-error.active {
    display: block;
}

.modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

  .modal.fade .modal-dialog {
    transform: translate(0, -100%);
}

  .modal.in .modal-dialog {
    transform: translate(0, 0);
}

#finalSubmitModal .modal-header,
#finalSubmitModal .modal-footer {
    border: none;
    display: none;
}


.modal .modal-content {
    border-radius: 0px !important;
}
.modal  .modal-body {
    padding: 2.5rem 1rem;
}

.modal button.close{
    position: absolute;
    top: 12px;
    right: 15px;
}

.errorMsg {
    color: $cherryAlertRed;
}

.errorMsg, .help-text,.element-error-msg{
    margin-top: 1rem;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.cookies-label{
    margin: 1rem 0px;
}


.clearfix{
    clear:both;
}


@media only screen and (max-width: 1200px)
{
    .nav-list li{
        width: 135px;
        font-size: 0.9rem;
    }

    .nav-list li span.progress-number
    {
        font-size: 1.4rem;
    }
}

@media only screen and (max-width:992px)
{
    div.pl-0, div.pr-0 {
        padding: 0px;
    }

}


@media only screen and (max-width: 900px) {

    .nav-list li {
        width: 80px;
        display: flex;
        justify-content: center;
    }
  

    .nav-list li span.progress-text {
        display: none;
    }

    .nav-list li span.progress-number {
        font-size: 1.2rem;
    }

    .HWP-main-container {
        padding: 0px;
    }
}